import Layout from "../components/layout";
import { signIn, signOut, useSession } from "next-auth/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { config } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css";
import User from "../components/user";
import Authorize from "../components/authorize";
import ServerStatus from "../components/serverStatus";
config.autoAddCss = false;

export default function Page() {
  const { data: session, status } = useSession();
  const loading = status === "loading";

  const sessionButton = () => {
    if (session?.user) {
      return (
        <div className="self-center">
          <button
            type="button"
            onClick={() => signOut()}
            className="inline-flex items-center px-6 py-3 border border-transparent text-base align-middle font-medium shadow-sm rounded-md text-white bg-zinc-500 hover:bg-zinc-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-zinc-400"
          >
            <span className="">Sign out</span>
          </button>
        </div>
      );
    } else {
      return (
        <div className="self-center">
          <button
            type="button"
            onClick={() => signIn("twitter")}
            className="inline-flex items-center px-6 py-3 border border-transparent text-base align-middle font-medium shadow-sm rounded-md text-white bg-sky-500 hover:bg-sky-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-400"
          >
            <FontAwesomeIcon icon={faTwitter} size="lg" />
            <span className="pl-2">Sign in with Twitter</span>
          </button>
        </div>
      );
    }
  };

  return (
    <Layout>
      <div className="flex flex-col items-center">
        <img
          src="/nagumi-minecraft.png"
          loading="lazy"
          alt="#ナ組マイクラ"
          className="w-96 self-center mt-6 mb-6"
        />
        <h1 className="mt-4 mb-6 block text-4xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl font-stick">
          マイクラ鯖ダッシュボード
        </h1>
        {session ? (
          <>
            <Authorize user={session.user}>
              <ServerStatus />
            </Authorize>
          </>
        ) : null}
        <div className="py-5">
          <User user={session?.user} />
        </div>
        {sessionButton()}
      </div>
    </Layout>
  );
}
