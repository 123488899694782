type UserProps = {
  user?: {
    image?: string;
    name?: string;
    username?: string;
  };
};
export default function User({ user }: UserProps) {
  const avater = () => {
    if (user?.image) {
      return (
        <img
          className="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0"
          src={user.image}
          alt={user.name}
        />
      );
    } else {
      return (
        <span className="inline-block h-10 w-10 rounded-full overflow-hidden bg-gray-100">
          <svg
            className="h-full w-full text-gray-300"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
          </svg>
        </span>
      );
    }
  };

  return (
    <div className="w-96 bg-white rounded-lg shadow divide-y divide-gray-200">
      <div className="w-full flex items-center justify-between p-6 space-x-6">
        <div className="flex-1 truncate">
          <div className="flex items-center space-x-3">
            <h3 className="text-gray-900 text-sm font-medium truncate">
              {user?.name ? user.name : "ログインしていません"}
            </h3>
          </div>
          <p className="mt-1 text-gray-500 text-sm truncate">
            {user?.username ? user.username : ""}
          </p>
        </div>
        {avater()}
      </div>
    </div>
  );
}
