import { useEffect, useState } from "react";
import { CheckCircleIcon, StopIcon } from "@heroicons/react/solid";

export default function ServerStatus() {
  const [instanceState, setInstanceState] = useState("loading");
  const [playerState, setPlayerState] = useState<number | null>(null);

  const infinityFetchSertverStatus = async (): Promise<NodeJS.Timeout> => {
    const server = await (
      await fetch("/api/server/status", { method: "POST" })
    ).json();
    const mc = await (await fetch("/api/mc/getStatus")).json();
    setInstanceState(server.status);
    setPlayerState(mc?.status?.playerCount);
    return setTimeout(infinityFetchSertverStatus, 1 * 60 * 1000); // 1分間隔
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    (async () => {
      timer = await infinityFetchSertverStatus();
    })();
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const serverStatusIcon = () => {
    if (instanceState === "RUNNING") {
      return (
        <CheckCircleIcon
          className="h-20 w-20 text-green-500"
          aria-hidden="true"
        />
      );
    } else if (instanceState === "TERMINATED") {
      return (
        <StopIcon className="h-20 w-20 text-gray-500" aria-hidden="true" />
      );
    } else {
      return (
        <div className="flex justify-center">
          <div className="animate-spin h-20 w-20 border-4 border-blue-500 rounded-full border-t-transparent"></div>
        </div>
      );
    }
  };

  const changeServerStatusButton = () => {
    if (instanceState === "TERMINATED") {
      return (
        <button
          type="button"
          className="inline-flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded-md text-green-800 bg-green-100 hover:bg-green-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm"
          onClick={() => {
            fetch("/api/server/start", { method: "POST" }).then(() => {
              setInstanceState("loading");
            });
          }}
        >
          起動する
        </button>
      );
    } else {
      return <></>;
    }
  };

  const playerCount = (state: number | null) => {
    if (state !== null) {
      return <div> : {state}</div>;
    } else {
      return (
        <div className="flex justify-center">
          <div className="animate-spin h-4 w-4 border-2 mx-2 border-gray-500 rounded-full border-t-transparent"></div>
        </div>
      );
    }
  };

  return (
    <div className="bg-white shadow rounded-lg w-96">
      <div className="px-4 py-5 sm:p-6 flex justify-between items-center">
        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            サーバーのようす
          </h3>
          <div className="mt-2 max-w-xl text-sm text-gray-700">
            <p>{instanceState === "RUNNING" ? "起動中" : "停止中"}</p>
          </div>
          <div className="mt-2 max-w-xl text-sm text-gray-700 flex flex-row items-center">
            <p>いまいる人数</p>
            {playerCount(playerState)}
          </div>
          <div className="mt-2 max-w-xl text-sm text-gray-700"></div>
          <div className="mt-5">{changeServerStatusButton()}</div>
        </div>
        {serverStatusIcon()}
      </div>
    </div>
  );
}
