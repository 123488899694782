import { XCircleIcon } from "@heroicons/react/solid";

type AuthorizeProps = {
  user: {
    username?: string;
  };
  children: React.ReactNode;
};

export default function Authorize({ user, children }: AuthorizeProps) {
  if (
    user?.username &&
    !process.env.NEXT_PUBLIC_ALLOWED_USERS_LIST?.includes(user.username)
  ) {
    return (
      <div className="rounded-md bg-red-50 p-4 py-5">
        <div className="flex">
          <div className="flex-shrink-0">
            <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">
              許可されていないユーザーです
            </h3>
          </div>
        </div>
      </div>
    );
  } else {
    return <>{children}</>;
  }
}
